import React from "react";

import { BACKEND_BASE_URL } from "../../constant/constants";

import Layout from "../../components/layout";
import { Seo } from "../../components";
import NewsDetail from "../../container/news-detail";

const NewsDetailPage = arg => {
  return (
    <Layout location={arg.location}>
      <Seo
          title={arg.serverData.news_info.title}
          description={arg.serverData.news_info.description}
          image_url={arg.serverData.news_info.image_url}
      />
      <NewsDetail data={arg.serverData.news_info} />
    </Layout>
  );
};

export async function getServerData(context) {
  try {
    const news_info_res = await fetch(
      BACKEND_BASE_URL + "news/" + context.params.uuid
    );

    if (!news_info_res.ok) {
      throw new Error(`Response failed`);
    }
    return {
      props: {
        news_info: await news_info_res.json(),
      },
    };
  } catch (error) {
    console.log(error);
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}

export default NewsDetailPage;
