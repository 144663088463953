import moment from "moment";
import React, { useMemo } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { Breadcrumb, MoreNewsSlider, GallerySlider } from "../components";

import WithService from "../service/withService";
import remarkBreaks from "remark-breaks";

const News = ({ response: sliderNews }) => {
  return (
    <>
      <h3 className="detail__title">More News</h3>
      <MoreNewsSlider sliderNews={sliderNews} />
    </>
  );
};

const NewsDetail = props => {
  const { data } = props;

  const breadcrumbData = useMemo(() => {
    return [
      {
        id: 1,
        name: "Home",
        link: "/",
      },
      {
        id: 2,
        name: "All News",
        link: "/more-news",
      },
      {
        id: 3,
        name: data?.title,
      },
    ];
  }, [data]);

  return (
    <div className="detail">
      <div className="container">
        <Breadcrumb data={breadcrumbData} />
        <h3 className="detail__title mb-0">{data?.title}</h3>
        <span className="detail__date">
          {moment(data?.published_timestamp).format("DD MMM YYYY")}
        </span>
        <div className="detail__img">
          <img src={data?.image_url} alt={data?.title} />
        </div>
        <p className="detail__desc mt-4">
          <ReactMarkdown
            children={data?.full_description}
            remarkPlugins={[remarkGfm,remarkBreaks]}
          />
        </p>
        <GallerySlider loading={false} data={data?.extra_images} />
        <div className="detail__divider" />
        <div className="row">
          <div className="col-12">
            <WithService endpoint="news">
              <News />
            </WithService>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsDetail;
